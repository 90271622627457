import { useEffect } from 'react'
import React from 'react'

const TrackClicks = ({ children }) => {
    useEffect(() => {
      const handleClick = (event) => {
        const target = event.target.closest('a')
        if (target) {
          const clickText = target.innerText || target.textContent;
          const clickUrl = target.href
  
          // Push the data to the GTM Data Layer
          window.dataLayer = window.dataLayer || []
          window.dataLayer.push({
            event: 'clickTracking',
            clickText: clickText,
            clickUrl: clickUrl,
          })
        }
      }
  
      // Add event listener to capture clicks
      document.addEventListener('click', handleClick);
  
      // Cleanup the event listener on unmount
      return () => {
        document.removeEventListener('click', handleClick)
      }
    }, [])
  
    return <div>{children}</div>
  }
  
  export default TrackClicks