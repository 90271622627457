import React from 'react'
import { useEffect } from 'react'
import "./src/assets/layout.css"
import { MergedProvider } from './src/components/Providers'
import TrackClicks from './src/components/trackClicks'

declare global {
  interface Window {
    dataLayer: any[]
  }
}

export const wrapRootElement = ({ element }) => {
  return <MergedProvider testMode={false} element={<TrackClicks>{element}</TrackClicks>} />
}

export const shouldUpdateScroll = ({ prevRouterProps, routerProps }) => {
  // console.log('not scrolling');
  // window.scrollTo(0, 0)
  return true
}

export const onRouteUpdate = ({ location, prevLocation }) => {
  try {  
    // Access the previously pushed data from the Data Layer
    const dataLayer = window.dataLayer || []
    const clickEvent = dataLayer.find(event => event.event === 'clickTracking')

    // console.log(dataLayer)

    const clickText = clickEvent ? clickEvent.clickText : null
    const clickUrl = clickEvent ? clickEvent.clickUrl : null

    // Push the gatsby-route-change event to GTM with additional parameters
    window.dataLayer.push({
      event: 'gatsby-route-change',
      route: location.pathname,
      prevRoute: prevLocation ? prevLocation.pathname : null,
      clickText: clickText,
      clickUrl: clickUrl,
    })
  } catch (error) {
    console.log('window datalayer is unavailable')
    console.log(error)
  }
}

